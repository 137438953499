import { Settings as defaults } from "./settings.default";
import { AiFillInstagram, AiFillFacebook } from "react-icons/ai";
import merge from "lodash.merge";
import { PaymentTypeEnum } from "@application/types/PaymentType.enum";
import { LANGUAGE } from "@config/lang";

export const Settings = merge(defaults, {
  endpoint: "https://admin.veneta-travel.com/",
  languages: [LANGUAGE.SQ, LANGUAGE.EN],
  encodeUrl: false,
  hashRouter: false,
  defaultLanguage: "sq",
  links: {
    top: ["phone", "email", "b2b", "lang"],
    menu: [],
  },
  menuLinks: [],
  googleAnalyticsTrackingId: null,
  contact: {
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2934.0945056195083!2d21.15619721172181!3d42.65935267104641!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13549ee42c2e4019%3A0x57f0da3812585013!2sVeneta%20Travel!5e0!3m2!1sen!2smk!4v1686417377252!5m2!1sen!2smk",
  },
  social: [
    {
      name: "Facebook",
      Icon: AiFillFacebook,
      url: "https://www.facebook.com/venetatravel/",
    },
    {
      name: "Instagram",
      Icon: AiFillInstagram,
      url: "https://www.instagram.com/venetatravel/",
    },
  ],
  search: {
    charter: {
      displayDepartures: true,
    },
    hotel: {
      displayDepartures: false,
    },
  },
  booking: {
    show_extend_hotel_night: false,
    passengers: {
      require_passport_no: true,
    },
    payments: [
      {
        type: PaymentTypeEnum.Cash,
        label: "payments.cash",
        public: true,
        agent: false,
      },
      {
        type: PaymentTypeEnum.CreditCard,
        label: "payments.cc",
        public: true,
        agent: false,
      },
      {
        type: PaymentTypeEnum.Invoice,
        label: "payments.inv",
        public: false,
        agent: true,
      },
    ],
  },
  iframe: {
    allowBooking: false,
  },
});
